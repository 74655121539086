import React from 'react';
import { ContentItem } from 'src/components/atom';
import { t, toTitleCase } from 'src/utils/commonMethods';

type TGeneralDeviceInfoProps = {
  deviceInfo: any;
  groups: any[];
};

const GeneralDeviceInfo = (props: TGeneralDeviceInfoProps) => {
  return (
    <>
      <ContentItem
        id="asset-number-value"
        title={t('section.general.asset_number')}
        value={props.deviceInfo.identity.assetNumber}
        marginTop={0}
      />
      <ContentItem
        id="device-group-value"
        title={t('section.general.device_group')}
        value={props.groups.map((item) => toTitleCase(item.groupName)).join(', ')}
      />
      <ContentItem
        id="date-added-value"
        title={t('section.general.date_added_to_account')}
        value={props.deviceInfo.timestamp.addedDate}
        addInfo={true}
        infoValue={t('section.general.date_added_info_msg')}
      />
      <ContentItem
        id="firmware-version-value"
        title={t('section.general.firmware_ver')}
        value={props.deviceInfo.identity.firmwareVersion}
      />
      <ContentItem
        id="manufacturer-value"
        title={t('section.general.manufacturer')}
        value={props.deviceInfo.identity.makeAndModel.manufacturer.toUpperCase()}
        data-testid="general-device-info-manufacturer"
      />
      <ContentItem
        id="serial-number-value"
        title={t('section.general.serial_num')}
        value={props.deviceInfo.identity.serialNumber}
        data-testid="general-device-info-serial-number"
      />
    </>
  );
};

export default GeneralDeviceInfo;

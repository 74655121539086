import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { t } from 'src/utils/commonMethods';

type TSeeMoreTextProps = {
  value: string;
};

const SeeMoreText = (props: TSeeMoreTextProps) => {
  const elementRef = useRef<HTMLElement>();
  const [renderSeeMore, setRenderSeeMore] = useState(false);
  const [renderExpanded, setRenderExpanded] = useState(false);

  useEffect(() => {
    trackEllipsis();
  }, []);

  const trackEllipsis = () => {
    const el = elementRef.current;
    /* istanbul ignore next */
    const isEllipsis = el?.scrollHeight > el?.clientHeight;

    if (isEllipsis) {
      setRenderSeeMore(true);
    } else {
      setRenderSeeMore(false);
    }
  };

  return (
    <Wrapper>
      {renderExpanded ? (
        <ExpandedValue>{props.value}</ExpandedValue>
      ) : (
        <InitialValue title={props.value} ref={elementRef} renderSeeMore={renderSeeMore}>
          {props.value}

          {renderSeeMore && (
            <SeeMoreLink
              data-testid={'see-more-link'}
              onClick={() => {
                setRenderSeeMore(false);
                setRenderExpanded(true);
              }}
            >
              {t('SeeMore')}
            </SeeMoreLink>
          )}
        </InitialValue>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: contents;
`;

const SeeMoreLink = styled.a`
  position: absolute;
  right: 0;
  bottom: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0070c0;
  text-decoration: none;
  cursor: pointer;
`;

const InitialValue = styled.div`
  font-style: normal;
  font-size: 16px;
  font-family: inherit;
  position: relative;

  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${(props) => {
    if (props.renderSeeMore) {
      return css`
        &::before {
          content: ' ....';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100px;
          height: 25px;
          background: white;
        }
      `;
    }
  }}
`;

const ExpandedValue = styled.div`
  font-style: normal;
  font-size: 16px;
  font-family: inherit;
`;

export default SeeMoreText;

import React, { useEffect, useState } from 'react';
import { getSystem } from 'src/styles/palette';
import { ThemeProvider } from '@veneer/core';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const CustomThemeProvider = ({ children }) => {
  const { theme, isWex, mode } = useShellRootContext();

  const [themeProviderProps, setThemeProviderProps] = useState({
    ...theme?.getThemeProviderProperties?.(),
    density: 'high',
    shape: 'round',
    mode: theme?.getUserThemeMode?.(),
  });

  useEffect(() => {
    if (isWex)
      setThemeProviderProps({
        ...themeProviderProps,
        density: 'high',
        shape: 'pill',
        mode: mode,
        customSemantics: getSystem(),
      });
  }, [mode]);
  return <ThemeProvider {...themeProviderProps}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;

import styled from 'styled-components';
import React from 'react';
import tokens from '@veneer/tokens';
import { t } from 'src/utils/commonMethods';
import { ContentItem } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TNetworkAdapterInfoProps = {
  adapters: any[];
  adapterType: string;
};

const NetworkAdapterInfo = (props: TNetworkAdapterInfoProps) => {
  const { isWex, mode } = useShellRootContext();
  const { adapters, adapterType } = props;

  const getAdapterType = (type) => {
    if (type === 'wifiAccessPoint') {
      return 'wifiAccessPoint';
    } else {
      return adapterType;
    }
  };

  return (
    <>
      {adapters
        .map((adapter, index) => {
          return (
            <>
              <NetworkTitle isWex={isWex} mode={mode}>
                {t(`section.general.${getAdapterType(adapter.type)}`)} {index > 0 ? index + 1 : ''}
              </NetworkTitle>
              {adapter.ipv4.address.ip != '--' && (
                <ContentItem
                  id={`${adapterType}${index}-ipv4-value`}
                  title={t('section.general.ipv4')}
                  value={adapter.ipv4.address.ip}
                />
              )}
              {adapter.ipv6.address.ip != '--' && (
                <ContentItem
                  id={`${adapterType}${index}-ipv6-value`}
                  title={t('section.general.ipv6')}
                  value={adapter.ipv6.address.ip}
                />
              )}
              {adapter.macAddress != '--' && (
                <ContentItem
                  id={`${adapterType}${index}-mac-value`}
                  title={t('section.general.mac')}
                  value={adapter.macAddress.replaceAll(':', '')}
                />
              )}
              {adapter.hostname != '--' && (
                <ContentItem
                  id={`${adapterType}${index}-hostname-value`}
                  title={t('section.general.hostName')}
                  value={adapter.hostname}
                />
              )}
            </>
          );
        })
        // Filter out empty items
        .filter((item) => {
          return item.props.children.filter((child) => child == false).length < 4;
        })}
    </>
  );
};

const NetworkTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 16px;
  padding-top: 24px;
  ${(props) => {
    if (props.isWex && props.mode == 'dark') return `color: ${tokens.color.white};`;
    else return 'color: #212121;';
  }}
`;

export default NetworkAdapterInfo;

import React from 'react';
import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { List, IconChevronRight, ListItem } from '@veneer/core';

type TSolutionListProps = {
  subItemList: any[];
};
const SolutionList = (props: TSolutionListProps) => {
  const { navigation, isWex, deviceId } = useShellRootContext();

  const handleNavigate = () => {
    navigation.push(`/solutions/security/devices/${deviceId}`);
  };

  return (
    <List data-testid="services-list">
      {props.subItemList.map((solution) => (
        <ListItem
          key={'link-list-item__' + solution.title}
          data-testid="service-item"
          leadingArea={solution.icon}
          centerArea={
            <TextWrapper>
              <Subtitle>{solution.title}</Subtitle>
              <DetailText>{solution.detail}</DetailText>
            </TextWrapper>
          }
          trailingArea={
            <LinkArea data-testid="link-list-handle-navigate" onClick={handleNavigate}>
              <LinkText isWex={isWex}>{solution.linkText}</LinkText>
              <IconChevronRight color={isWex ? 'darkNavy7' : 'hpBlue7'} />
            </LinkArea>
          }
        />
      ))}
    </List>
  );
};

const TextWrapper = styled.div`
  display: inline-grid;
  margin-left: 8.5px;
  min-height: 48px;
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`;

const DetailText = styled.label`
  min-height: 24px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;

const LinkArea = styled.div`
  color: #027aae;
  cursor: pointer;
  display: flex;
`;

const LinkText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 3px;
  color: ${(props) => (props.isWex ? tokens.color.darkNavy7 : '#027aae')};
`;

export default SolutionList;

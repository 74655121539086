import React from 'react';
import ShellRootContext from './ShellRootContext';
import { Stack } from '@jarvis/react-mfe-component/dist/src';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { objHasKeys } from 'src/utils/commonMethods';

export const ShellRootProvider = (props) => {
  const flags = useFlags();

  const getContainerId = () => {
    if (props.mode === 'demo') {
      return undefined;
    }
    if (props.isWex) {
      return 'wex-main-container-id';
    } else if (props.isFromApps) {
      return 'jarvis-react-shell-common-layout__layout-scrollable-container';
    } else {
      return props.containerId;
    }
  };

  return (
    <ShellRootContext.Provider
      value={{
        ecpDeviceV55: props.ecpDeviceV55,
        stack: props.stack ?? Stack.pie,
        localization: props.localization,
        useToast: props.useToast(),
        authProvider: props.authProvider,
        navigation: props.navigation,
        featureFlags: objHasKeys(props, ['featureFlags']) ? props.featureFlags : flags,
        containerId: getContainerId(),
        theme: props.theme,
        isWex: props.isWex,
        mode: props.mode,
        deviceId: props.deviceId,
        isFromApps: props.isFromApps,
        appUuid: props.appUuid,

        //for wx demo mode
        demoEnabled: props.demoEnabled ?? false,
        apiPath: props.apiPath,
        demoModeOptions: props.demoModeOptions,
        requestOptions: props.requestOptions,
      }}
    >
      {props.children}
    </ShellRootContext.Provider>
  );
};

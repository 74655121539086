import { createContext } from 'react';

const ecpDeviceV55 = null;
const stack = null;
const localization = null;
const useToast = null;
const authProvider = null;
const navigation = null;
const featureFlags = null;
const containerId = null;
const deviceId = null;
const theme = null;
const isWex = null;
const mode = null;
const isFromApps = null;
const appUuid = null;
const demoEnabled = null;
const apiPath = null;
const demoModeOptions = null;
const requestOptions = null;

const shellRootContext = createContext({
  ecpDeviceV55,
  stack,
  localization,
  useToast,
  authProvider,
  navigation,
  featureFlags,
  containerId,
  deviceId,
  theme,
  isWex,
  mode,
  isFromApps,
  appUuid,
  demoEnabled,
  apiPath,
  demoModeOptions,
  requestOptions,
});

export default shellRootContext;

import { refined } from 'src/utils/commonMethods';

export const refinedGetAppInfo = (data) => {
  const refinedArray = (data) => {
    if (data === undefined || data === null) {
      return [];
    } else {
      return data;
    }
  };

  return data?.length > 0
    ? data.map((item) => ({
        appUuid: refined(item.appUuid),
        title: refined(item.title),
        icon: refined(item.icon),
        localeInfoList: refinedArray(item.localeInfoList).map((li) => ({
          locale: refined(li.locale),
          description: refined(li.description),
          screenshotList: refined(li.screenshotList),
        })),
        simplifiedAppID: refined(item.simplifiedAppID),
        supportLink: refined(item.supportLink),
        platformType: refined(item.platformType),
        platformVersion: refined(item.platformVersion),
        appSize: refined(item.appSize),
        isFree: refined(item.isFree),
        permissionList: refinedArray(item.permissionList),
        mocId: refined(item.mocId),
        resourceId: refined(item.resourceId),
      }))
    : [];
};

import { fleetMgtAPI } from 'src/api/fleetMgt';
import { collectionAPI } from 'src/api/collection';
import { filterOptions } from 'src/utils/constants';
import { fleetProxyAPI } from 'src/api/fleetProxy';
import { deviceCacheAPI } from 'src/api/deviceCache';
import { contentBrokerAPI } from 'src/api/contentBroker';
import { getFormattedDate } from 'src/utils/commonMethods';

export const apiGetDeviceInfo = async (deviceId) => {
  console.log('[overview] apiGetDeviceInfo');
  const { getCollectionByEntityId, getCollections } = collectionAPI();
  const { getDeviceInfo } = deviceCacheAPI();

  //get device's group info
  const groupInfoByEntity = await getCollectionByEntityId(deviceId, {
    ...filterOptions,
    offset: 0,
    limit: -1,
  });
  let allGroups;
  const allGroupInfo = await getCollections(0, 1, 'All');
  allGroups = [{ groupId: allGroupInfo.response.contents[0].id, groupName: 'All' }];

  if (allGroupInfo.error !== undefined || groupInfoByEntity.error !== undefined) {
    return { groups: [], details: [], error: true };
  }

  if (
    !(groupInfoByEntity.response.length === 1 && groupInfoByEntity.response[0].name === 'Ungrouped')
  ) {
    // If there is group info except 'Ungrouped'
    allGroups = [
      ...allGroups,
      ...groupInfoByEntity.response.map((groupInfo) => {
        return { groupId: groupInfo.id, groupName: groupInfo.name };
      }),
    ];
  }
  //get device details info
  const deviceInfo = await getDeviceInfo(deviceId);

  return { groups: allGroups, details: deviceInfo.response, error: deviceInfo.error !== undefined };
};

export const apiGetFleetProxy = async (details, isWex, ecpDeviceV55) => {
  console.log('[overview] apiGetFleetProxy');
  const { getProxy } = fleetProxyAPI();
  const newConnTypes = [];
  const proxyURL = isWex ? '/fleet-management/printProxies' : '/proxies';

  if (details.connTypes.includes('print-iot')) {
    newConnTypes.push({
      name: 'Cloud',
      connType: 'print-iot',
      lastUpdated: getFormattedDate('MMM DD, YYYY hh:mm A', details.lastUpdatedAt),
    });
  }

  if (details.connTypes.includes('fleet-proxy') && details.proxyIds.length > 0) {
    for (const proxyId of details.proxyIds) {
      const fleetProxies = await getProxy(proxyId);
      if (fleetProxies.error != undefined) {
        return { connTypes: [], error: true };
      }

      if (
        (fleetProxies.response != undefined || fleetProxies.response != null) &&
        fleetProxies.response
      ) {
        newConnTypes.push({
          name: fleetProxies.response.proxyName,
          connType: 'fleet-proxy',
          lastUpdated: getFormattedDate(
            'MMM DD, YYYY hh:mm A',
            fleetProxies.response.lastUpdated,
            // getProps().language,
          ),
          url: proxyURL,
          status: fleetProxies.response.connectionState.status.toLowerCase(),
        });
      }
    }
  }

  if (details.connTypes.includes('usb') && ecpDeviceV55) {
    newConnTypes.push({
      name: 'USB',
      connType: 'usb',
      lastUpdated: getFormattedDate(
        'MMM DD, YYYY hh:mm A',
        details.lastUpdatedAt,
        // getProps().language,
      ),
    });
  }

  if (newConnTypes.length == 0) {
    return { connTypes: [], error: true };
  }

  return { connTypes: newConnTypes, error: false };
};

export const apiGetAssignments = async (deviceId) => {
  console.log('[overview] apiGetAssignments');
  const { getCompliance } = fleetMgtAPI();
  const { response, error } = await getCompliance(deviceId);

  if (error != undefined) {
    return { assignments: [], error: true };
  }

  return { assignments: response, error: false };
};

export const apiGetAppInfo = async (appList) => {
  console.log('[overview] apiGetAppInfo');
  const { getAppInfo } = contentBrokerAPI();

  const { response: resAppContents, error: errorAppContents } = await getAppInfo({
    propertyName: 'uuid',
    propertyValue: appList.map((app) => app.uuid),
  });

  if (errorAppContents != undefined) {
    return { appList: [], appContents: [], error: true };
  }

  return { appContents: resAppContents, error: false };
};

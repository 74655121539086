import moment from 'moment';
import AssetsProviderFactory from 'src/assets/AssetsProviderFactory';
import { dateFormat } from 'src/utils/constants';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { useShellRootContext } from 'src/contexts/ShellRoot';

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isMockUpMode = () => {
  return !!(
    (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') &&
    localStorage.getItem('enable-devices-mockup-data')
  );
};

export const getMockUpData = async (moduleName: string) => {
  return (await import(`src/mock/${moduleName}.json`)).default;
};

type ManageMfeProps = {
  authProvider: JarvisAuthProvider;
  stack?: number;
  country?: string;
  language?: string;
  isWex?: boolean;
  demoEnabled?: boolean;
  apiPath?: any;
};

const localProps: ManageMfeProps = {
  language: 'en',
  stack: 1,
  country: 'US',
  authProvider: null,
  isWex: false,
  demoEnabled: false,
  apiPath: null,
};

export const setProps = () => {
  const { authProvider, localization, stack, isWex, demoEnabled, apiPath } = useShellRootContext();
  localProps.authProvider = authProvider;
  localProps.language = localization.language;
  localProps.stack = stack ?? 1;
  localProps.country = localization.country;
  localProps.isWex = isWex;
  localProps.demoEnabled = demoEnabled;
  localProps.apiPath = apiPath ?? null;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(
    props.language || 'en',
    props.country || 'US',
  );
  return assetsProvider.getText(`ecp-device-details-overview.${subkey}`, args);
};

export const objHasKeys = (obj, keys) => {
  const next = keys.shift();
  return obj[next] && (!keys.length || objHasKeys(obj[next], keys));
};

export const getFormattedDate = (format, date) => {
  if (date === undefined) return '--';
  moment.locale('en');
  return moment(date).format(format);
};

export const refined = (data: any) => {
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    return data;
  }
};

export const refinedArray = (data: any) => {
  if (data === undefined || data === null || !data) {
    return [];
  }
  return data;
};

export const refinedDate = (data: any, format: string = dateFormat.default) => {
  moment.locale('en');
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    if (moment(data).isValid()) return moment(data).format(format);
    else return '--';
  }
};

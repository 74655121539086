import styled from 'styled-components';
import React, { useState } from 'react';
import tokens from '@veneer/tokens';
import { t } from 'src/utils/commonMethods';
import { MfeLoader } from 'src/components/atom/index';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { IconChevronRight, IconSmartApp, Modal } from '@veneer/core';

type TAppStatusProps = {
  appState: string;
  deployState: string;
  resourceId: string;
  mocId: string;
  iconSrc: string;
  title: string;
};

const AppStatus = (props: TAppStatusProps) => {
  const [showModal, setShowModal] = useState(false);
  const { isWex, featureFlags } = useShellRootContext();

  const getIconImg = (url) => {
    if (url === undefined || url === '--') {
      return <IconSmartApp size={24} />;
    }
    return <StyledApp src={url} />;
  };

  const getAppConfig = () => {
    const appConfig = {
      appId: props.resourceId,
      mocId: props.mocId,
      header: (
        <FlexRow className={'alignCenter'}>
          {getIconImg(props.iconSrc)}
          <AppTitle>{props.title}</AppTitle>
        </FlexRow>
      ),
      config: false,
      onClose: () => {
        setShowModal(false);
      },
      preview: true,
    };

    return (
      <Modal show={showModal}>
        <MfeLoader
          disableContainerPadding
          {...{
            type: 'ECPDevicesDetailsPolicy',
            component: '@jarvis/react-ecp-policies',
            appConfig: appConfig,
          }}
        />
      </Modal>
    );
  };
  const getAppInstallationStatus = () => {
    // let statusColor = '';
    // let installText = '';

    // const isInstalled = props.appState === 'EXIST';
    // const isNotInstalled = props.appState !== 'EXIST' && props.deployState === 'FAILED';
    // const isInstalling =
    //   props.appState !== 'EXIST' &&
    //   (props.deployState === 'RUNNING' || props.deployState === 'READY');

    // if (isInstalled) {
    //   statusColor = 'green';
    //   installText = t('section.apps.installed');
    // } else if (isInstalling) {
    //   statusColor = 'blue';
    //   installText = t('section.apps.installing');
    // } else if (isNotInstalled) {
    //   statusColor = 'red';
    //   installText = t('section.apps.not_installed');
    // }

    return (
      <Wrapper>
        {featureFlags.enableShowAppConfig && (
          <ViewDetailsArea
            data-testid="app-status-view-details"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <ViewDetails isWex={isWex}>{t('view_details')}</ViewDetails>
            <IconChevronRight color={isWex ? 'darkNavy7' : 'hpBlue7'} />
          </ViewDetailsArea>
        )}
      </Wrapper>
    );
  };

  return (
    <>
      {getAppInstallationStatus()} {showModal && getAppConfig()}
    </>
  );
};

const Wrapper = styled.div`
  max-width: 224px;
  display: flex;
  align-items: center;
`;
//
// const AppIcon = styled.div`
//   width: 8px;
//   height: 8px;
//   background-color: ${(props) => {
//     switch (props.color) {
//       case 'green':
//         return '#1C7A17';
//       case 'blue':
//         return '#0278AB';
//       case 'red':
//       default:
//         return '#BE1313';
//     }
//   }};
//   margin-right: 8px;
//   display: inline-block;
//   border-radius: 50%;
// `;
//
// const AppText = styled.div`
//   font-weight: bold;
// `;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const AppTitle = styled.span`
  margin-left: 10px;
`;

const ViewDetailsArea = styled.div`
  color: #027aae;
  cursor: pointer;
  display: flex;
`;

const ViewDetails = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 3px;
  color: ${(props) => (props.isWex ? tokens.color.darkNavy7 : '#027aae')};
`;

const StyledApp = styled.img`
  width: 24px !important;
  height: 24px !important;
`;
export default AppStatus;
